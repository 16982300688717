import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import { styled } from '@mui/system';
import theme from '../theme';

import PreviewCompatibleImage from './PreviewCompatibleImage';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';

import BnWSection from '../components/BnWSection';
import BnWContent from '../components/BnWContent';

const Section = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	// backgroundColor: '#ffffff',
	zIndex: 1,
	top: 64,
});

const PostCard = styled(Card)(({ theme }) => ({
	display: 'flex',
	// flexDirection: 'column',
	// justifyContent: 'center',
	position: 'relative',
	// color: theme.palette.primary.contrastText,
	margin: theme.spacing(2),
	backgroundColor: 'initial',
	padding: theme.spacing(4),
	zIndex: 0,
	':after': {
		position: 'absolute',
		display: 'block',
		width: 'calc(100% - 16px)',
		height: 'calc(100% - 16px)',
		content: '" "',
		top: '8px',
		left: '8px',
		// border: '1px solid rgba(0, 0, 0, 0.15)',
		border: '4px solid rgba(255, 255, 255, 0.45)',
		// zIndex: -1,
		borderRadius: '5px',
	},
	'.MuiCardHeader-root': {
		// height: '220px',
		// color: theme.palette.primary.contrastText,
	},
	'.MuiCardContent-root': {
		// padding: theme.spacing(0, 4, 2),
		// paddingBottom: theme.spacing(2),
	},
	'.underColor': {
		backgroundColor: '#ffffff75',
	},
	'.filter': {
		position: 'absolute',
		backgroundColor: 'rgba(0,0,0, 0.1)',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		textAlign: 'center;',
		zIndex: '0',
		transition: 'all 0.5s ease',
	},
	'&:hover': {
		transition: 'all 0.5s ease',
		color: theme.palette.primary.main,
		'& .filter': {
			backgroundColor: 'rgba(255, 255, 255, 0.9)',
			transition: 'all 0.5s ease',
			transform: 'scale(1.2)',
		},
	},
}));

class BlogRollTemplate extends React.Component {
	render() {
		const { data } = this.props;
		const { edges: posts } = data.allMarkdownRemark;

		return (
			<BnWSection sx={{ backgroundColor: 'transparent' }}>
				<BnWContent
					p={{ xs: 0, md: 2 }}
					sx={{
						// m: 0,
						display: 'flex',
						justifyContent: 'center',
						flexWrap: 'wrap',
					}}
				>
					{posts &&
						posts.map(({ node: post }) => (
							<PostCard
								key={post.id}
								sx={{
									width: { xs: '100%', md: '45%', lg: '30%' },
								}}
							>
								{post.frontmatter.featuredimage ? (
									<Box sx={{ zIndex: -1, 'img:': {} }}>
										<PreviewCompatibleImage
											imageInfo={{
												image: post.frontmatter.featuredimage,
												alt: `featured image thumbnail for post ${post.frontmatter.title}`,
											}}
										/>
									</Box>
								) : null}
								<Box sx={{ display: 'flex', flexDirection: 'column', zIndex: 1 }}>
									<CardHeader
										// className='header' sx={{ zIndex: 1 }}
										title={
											<Typography variant='h3'>
												<span className='underColor'>{post.frontmatter.title}</span>
											</Typography>
										}
										subheader={
											<Typography variant='body2'>
												<span className='underColor'>{post.frontmatter.date}</span>
											</Typography>
										}
										titleTypographyProps={{ color: theme.palette.main }}
									/>
									<CardContent
									// sx={{  width: '60%' }}
									>
										{/* <Typography variant='h3'>{post.frontmatter.title}</Typography> */}
										<Typography variant='body1' className='description'>
											<span className='underColor'>{post.excerpt}</span>
										</Typography>
										{/* <Typography
											dangerouslySetInnerHTML={{
												__html: post.frontmatter.description.html,
											}}
										/> */}
									</CardContent>
									<CardActions
										className='footer text-center'
										// sx={{
										// 	display: 'flex',
										// 	p: 2,
										// 	pb: 4,
										// 	justifyContent: 'center',
										// 	zIndex: 1,
										// }}
									>
										<Button
											variant='contained'
											component={Link}
											to={post.fields.slug}
											size='large'
											sx={{ px: 2, py: 1, fontSize: '0.8rem' }}
										>
											Continue Reading
										</Button>
									</CardActions>
								</Box>
								<Box className='filter'></Box>
							</PostCard>
						))}
				</BnWContent>
			</BnWSection>
		);
	}
}

BlogRoll.propTypes = {
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.array,
		}),
	}),
};

export default function BlogRoll() {
	return (
		<StaticQuery
			query={graphql`
				query BlogRollNuQuery {
					allMarkdownRemark(
						sort: { order: DESC, fields: [frontmatter___date] }
						filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
					) {
						edges {
							node {
								excerpt(pruneLength: 400)
								id
								fields {
									slug
								}
								frontmatter {
									title
									templateKey
									date(formatString: "MMMM DD, YYYY")
									featuredpost
									featuredimage {
										childImageSharp {
											gatsbyImageData(quality: 100, layout: FULL_WIDTH)
										}
									}
								}
							}
						}
					}
				}
			`}
			render={(data, count) => <BlogRollTemplate data={data} count={count} />}
		/>
	);
}
