import React from 'react';
import { Link } from 'gatsby';
import useSiteMetadata from './hooks/SiteMetadata';
import { useParallax } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const SectionHeader = ({ title }) => {
	const { secondaryLogo } = useSiteMetadata();

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignContent: 'center',
				flexDirection: 'column',
				position: 'relative',
				// backgroundColor: theme.palette.primary.main,
				'.logoMover': {
					top: '-20px',
				},
			}}
			className='sectionHeader'
		>
			<Typography
				variant='h1'
				color='primary.light'
				align='center'
				p={{ xs: 2, md: 8 }}
				pb={0}
				sx={{
					width: '100%',
					textTransform: 'uppercase',
					fontSize: { xs: '7vw', md: '2.5rem' },
				}}
			>
				{title}
			</Typography>
			<Parallax speed={50} className='logoMover'>
				<PreviewCompatibleImage
					widthInner='30%'
					height='auto'
					left='10%'
					top='-40px'
					imageInfo={{
						image: secondaryLogo,
						// alt: `featured image thumbnail for product ${product.name}`,
					}}
				/>
			</Parallax>
		</Box>
	);
};

export default SectionHeader;
