import * as React from 'react';
import { styled } from '@mui/system';

import Box from '@mui/system/Box';

import Layout from '../../components/Layout';
import BlogRoll from '../../components/BlogRoll';
import { Container, Typography } from '@mui/material';
import SectionNavComponent from '../../components/Navbar-v3';
import SectionHeader from '../../components/SectionHeader';

const MainContent = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	// width: '100%',
	zIndex: 1,
});

export default class BlogIndexPage extends React.Component {
	render() {
		return (
			<Layout>
				<SectionHeader title='Latest Articles' />
				<SectionNavComponent sx={{ mb: 0 }} />
				<Container>
					<MainContent className='section' sx={{ pt: 4 }}>
						<BlogRoll />
					</MainContent>
				</Container>
			</Layout>
		);
	}
}
